
// // PaymentStatus.js
// import React, { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
// import Footer from '../Footer/Footer';
// import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
// import '../Checkout/paymentstatus.css'; // Import custom CSS for animation
// import LandingHeader from '../LandingPage/LandingHeader';
// import { clearCart } from '../../Redux/cartActions';

// const PaymentStatus = () => {
//   const [paymentId, setPaymentId] = useState(null);
//   const [token, setToken] = useState(null);
//   const [status, setStatus] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [isSuccessful, setIsSuccessful] = useState(null);
//   const [userId, setUserId] = useState(null);
//   const [productId, setProductId] = useState(null);

//   const dispatch = useDispatch();

//   useEffect(() => {
//     // Extract paymentId and token from the URL path
//     const url = window.location.pathname;
//     const segments = url.split('/');
//      // Assuming the URL format is: /pretty/pg/v1/payment-status/:trid/:mt/:userId
//      const trid = segments[segments.length - 4]; // Transaction ID
//      const mt = segments[segments.length - 3];  // Token
//      const userId = segments[segments.length - 2]; // User ID
//      const pId = segments[segments.length - 1]; // Product ID


//     // console.log(trid)
//     // console.log(mt)
//     // console.log(userId)
//     // console.log(pId)

//     setPaymentId(trid);
//     setToken(mt);
//     setUserId(userId);
//     setProductId(pId);

//     async function fetchStatus() {
//       try {
//         const response = await fetch(`https://sjpapi.prettywomen.in/API/v1/pretty/pg/v1/payment-status/${trid}/${mt}/${userId}/${pId}`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             trid: trid,
//             mt: mt,
//             userId: userId,
//             pId: pId
//           }),
//         });

//         const result = await response.text();
//         setStatus(result);

//         // Determine if payment was successful
//         if (result.includes('Payment Successful')) {

//           setIsSuccessful(true);
//           dispatch(clearCart()); // Clear the cart if payment is successful
//         } else {
//           setIsSuccessful(false);
//         }

//       } catch (error) {
//         console.error('Error fetching payment status:', error);
//         setStatus('Error fetching payment status');
//         setIsSuccessful(false);
//       } finally {
//         setLoading(false);
//       }
//     }

//     fetchStatus();
//   }, [dispatch]);


//   if (loading) {
//     return <div>Loading...</div>;
//   }



//   return (
//     <>
//       <LandingHeader />
//       <div className="container mt-4">
//         <div className="card-status">
//         <p className="text-center"><strong>Transaction ID:</strong> {paymentId}</p> {/* Display the transaction ID */}
//         <div className="text-center" dangerouslySetInnerHTML={{ __html: status }}></div>
//           {isSuccessful === true && (
//             <div className="card-io card-success">
//               <div className="card-body">
//                 <FaCheckCircle className="icon-success" />
//                 <h5 className="card-title">Payment Successful</h5>
//                 <p className="card-text">Transaction was completed successfully.</p>
//               </div>
//             </div>
//           )}
//           {isSuccessful === false && (
//             <div className="card card-failure">
//               <div className="card-body">
//                 <FaTimesCircle className="icon-failure" />
//                 <h5 className="card-title">Payment Failed</h5>
//                 <p className="card-text">There was an issue with the transaction.</p>
//               </div>
//             </div>
//           )}
//            {isSuccessful === null && (
//             <div className="card card-info">
//               <div className="card-body">
//                 <h5 className="card-title">Status</h5>
//                 <p className="card-text">{status}</p> {/* Display the status text */}
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default PaymentStatus;










import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Footer from '../Footer/Footer';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import '../Checkout/paymentstatus.css'; // Custom CSS for animation
import LandingHeader from '../LandingPage/LandingHeader';
import { clearCart } from '../../Redux/cartActions';
import axios from 'axios';

const PaymentStatus = () => {
  const [transactionId, setTransactionId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSuccessful, setIsSuccessful] = useState(null);
  const [username, setUsername] = useState('');
  const [orderId, setOrderId] = useState('');
  const [userMobile, setUserMobile] = useState('');
  const [productId, setProductId] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [token, setToken] = useState(null);
  const [status, setStatus] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const dispatch = useDispatch();


  useEffect(() => {
    const url = window.location.pathname;
    const segments = url.split('/');
    const trid = segments[segments.length - 5]; // Transaction ID
    const mt = segments[segments.length - 4];  // Token
    const userId = segments[segments.length - 3]; // User ID
    const pId = segments[segments.length - 2]; // Product ID
    const shipCharge = segments[ segments.length - 1];

    setPaymentId(trid);
    setToken(mt);
    setUserId(userId);
    setProductId(pId);
    setTransactionId(trid);
    

    async function fetchStatus() {
      try {
        const response = await fetch(`https://sjpapi.prettywomen.in/API/v1/pretty/pg/v1/payment-status/${trid}/${mt}/${userId}/${pId}/${shipCharge}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ trid, mt, userId, pId, shipCharge }),
        });

        const result = await response.text();
        setStatus(result);

        // Determine if payment was successful
        if (result.includes('Payment Successful')) {
          setIsSuccessful(true);
          dispatch(clearCart()); // Clear the cart if payment is successful
          await removeItemFromCart(userId, pId); 

        } else {
          setIsSuccessful(false);
        }

      } catch (error) {
        console.error('Error fetching payment status:', error);
        setStatus('Error fetching payment status');
        setIsSuccessful(false);
      } finally {
        setLoading(false);
      }
    }

    async function fetchTransactionDetails() {
      try {
        const response = await fetch(`https://sjpapi.prettywomen.in/API/v1/api/transactions/${trid}/${userId}`);
        const result = await response.json();

        if (response.ok) {
          const { user, transaction } = result;
          const { username, mobile, email, } = user;
          const { orderId } = transaction;


          setUsername(username);
          setOrderId(orderId);
          setUserMobile(mobile);
          setUserEmail(email); // Set user email

          // console.log(email)
          // console.log(username)
          // console.log(mobile)
          // console.log(orderId)

          // Send email notification and notify order API if payment was successful

          await sendEmailNotification(username, orderId, email);
          await notifyOrderAPI(username, mobile, orderId);

        } else {
          setPaymentStatus('Transaction not found');
          setIsSuccessful(false);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching transaction details:', error);
        setPaymentStatus('Error fetching transaction details');
        setIsSuccessful(false);
        setLoading(false);
      }
    }


    async function removeItemFromCart(userId, productId) {
      try {
        const response = await axios.delete('https://sjpapi.prettywomen.in/API/v1/cart/remove', {
          data: { userId, productId },
        });

        if (response.status === 200) {
          console.log('Item removed from cart successfully');
        } else {
          console.error('Failed to remove item from cart');
        }
      } catch (error) {
        console.error('Error removing item from cart:', error);
      }
    }




    async function sendEmailNotification(username, orderId, userEmail) {
      try {
        const response = await fetch('https://sjpapi.prettywomen.in/API/v1/api/send-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username, orderId, userEmail }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to send email');
        }

        const data = await response.json();
        console.log(data.message);
      } catch (error) {
        console.error('Error sending email notification:', error);
        setPaymentStatus('Error sending email notification. Please check your details.');
      }
    }

    async function notifyOrderAPI(username, mobile, orderId) {      //https://emedha.com/pretty/order.php?mobile=919886642994&name=uday&code=12345
      try {
        const response = await fetch(`https://emedha.com/pretty/order.php?mobile=${mobile}&name=${username}&code=${orderId}`, {
          method: 'GET',
        });

        if (!response.ok) {
          throw new Error('Failed to notify order API');
        }

        const data = await response.json();
        console.log('Order API Response:', data);
      } catch (error) {
        console.error('Error notifying order API:', error);
      }
    }

    // Execute functions in the desired order
    (async () => {
      await fetchStatus();
      await fetchTransactionDetails();
    })();
  }, [dispatch]);




  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <LandingHeader />
      <div className="container mt-4">
        <div className="card-status">
          <p className="text-center"><strong>Transaction ID:</strong> {paymentId}</p> {/* Display the transaction ID */}
          <div className="text-center" dangerouslySetInnerHTML={{ __html: status }}></div>
      
          {isSuccessful === true && (
            <div className="card-io card-success">
              <div className="card-body">
                <FaCheckCircle className="icon-success" />
                <h5 className="card-title">Payment Successful</h5>
                <p className="card-text">Transaction was completed successfully.</p>
                <p>Cut off time for dispatch your product daily before 4 pm IST</p>
              </div>
            </div>
          )}
          {isSuccessful === false && (
            <div className="card card-failure">
              <div className="card-body">
                <FaTimesCircle className="icon-failure" />
                <h5 className="card-title">Payment Failed</h5>
                <p className="card-text">There was an issue with the transaction.</p>
              </div>
            </div>
          )}
          {isSuccessful === null && (
            <div className="card card-info">
              <div className="card-body">
                <h5 className="card-title">Status</h5>
                <p className="card-text">{status}</p>
              </div>
            </div>
          )}
        </div>

      </div>
      <Footer />
    </>
  );
};

export default PaymentStatus;

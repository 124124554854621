// import React, { useState, useEffect } from 'react';
// import Container from 'react-bootstrap/Container';
// import Form from 'react-bootstrap/Form';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import { Link, useNavigate } from 'react-router-dom';
// import Logor from '../images/Logop.png';
// import { RiAccountCircleLine } from 'react-icons/ri';
// import Dropdown from 'react-bootstrap/Dropdown';
// import { BsCart3, BsHeart } from 'react-icons/bs';
// import { IoSearchOutline } from "react-icons/io5";
// import { useSelector } from 'react-redux';
// import { Modal } from 'react-bootstrap';
// import { FaSignInAlt } from 'react-icons/fa';
// import { FaUserShield } from 'react-icons/fa6';
// import { AiOutlineUser } from 'react-icons/ai';


// function LandingHeader({ setSearch }) {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [showSearchModal, setShowSearchModal] = useState(false);
//   const [searchQuery, setSearchQuery] = useState('');
//   const navigate = useNavigate();
//   const wishlistItems = useSelector(state => state.wishlist.wishlistItems);
//   const totalWishlistItems = wishlistItems.length;
//   const totalQuantity = useSelector(state => state.cart.totalQuantity);


//   useEffect(() => {
//     const token = localStorage.getItem('userData');
//     setIsLoggedIn(!!token);
//   }, []);


//   const handleSearchChange = (event) => {
//     setSearchQuery(event.target.value);
//     console.log('searchQuery updated:', event.target.value);
//   };

//   const handleSearchSubmit = (event) => {
//     console.log('searchQuery:', searchQuery);
//     if (event.key === 'Enter') {
//       navigate(`/searchresults?query=${encodeURIComponent(searchQuery)}`);
//       setShowSearchModal(false);
//     }
//   };

//   const handleLogin = () => {
//     navigate('/myaccount');
//   };


//   const handleSearchModalOpen = () => setShowSearchModal(true);
//   const handleSearchModalClose = () => setShowSearchModal(false);

//   const cartLength = localStorage.getItem('cartlength')

//   return (
//     <>
//       <Navbar expand="lg" style={{ fontFamily: 'Arial, sans-serif' }}>
//         <Container>
//           {/* Visible on lg and md screens */}
//           <Navbar.Brand as={Link} to="/">
//             <img src={Logor} className='me-auto d-none d-md-flex' style={{ height: '90px', width: '250px', borderRadius: '20px' }} alt="Logo" />
//           </Navbar.Brand>
//           <Nav className="me-auto d-none d-md-flex">
//             <Nav.Link as={Link} to="/"><strong className='text-black'>Home</strong></Nav.Link>
//             <Nav.Link as={Link} to="/about"><strong className='text-black'>About</strong></Nav.Link>
//             <Nav.Link as={Link} to="/trend"><strong className='text-black'>Trending</strong></Nav.Link>
//             <Nav.Link as={Link} to="/brace"><strong className='text-black'>Bracelets</strong></Nav.Link>
//             <Nav.Link as={Link} to="/earrings"><strong className='text-black'>Earrings</strong></Nav.Link>
//             <Nav.Link as={Link} to="/necklaces"><strong className='text-black'>Necklaces</strong></Nav.Link>
//             <Nav.Link as={Link} to="/rings"><strong className='text-black'>Rings</strong></Nav.Link>
//           </Nav>
//           <Nav className="my-lg-0 d-none d-md-flex align-items-center" style={{ fontSize: '16px' }}>
//             <div className="d-flex align-items-center">
//               <IoSearchOutline size={25} onClick={handleSearchModalOpen} style={{ cursor: 'pointer' }} />
//               <Modal show={showSearchModal} onHide={handleSearchModalClose}>
//                 <div className="d-flex">
//                   <input
//                     type="search"
//                     placeholder="Search"
//                     aria-label="Search"
//                     value={searchQuery}
//                     onChange={handleSearchChange}
//                     onKeyPress={handleSearchSubmit}
//                   />
//                 </div>
//               </Modal>
//             </div>
//             <Dropdown align="end" className="ms-3">
//               <Dropdown.Toggle variant="link" id="account-dropdown" className="text-black d-flex align-items-center">
//                 <RiAccountCircleLine size={25} />
//               </Dropdown.Toggle>
//               <Dropdown.Menu>
//                 {isLoggedIn ? (
//                   <Dropdown.Item onClick={handleLogin} style={{ display: 'flex', alignItems: 'center' }}>
//                     <AiOutlineUser style={{ marginRight: '5px' }} />
//                     MY ACCOUNT
//                   </Dropdown.Item>
//                 ) : (
//                   <>
//                     <Dropdown.Item as={Link} to="/login">
//                       <FaSignInAlt style={{ marginRight: '8px' }} />
//                       Login In
//                     </Dropdown.Item>
//                     <Dropdown.Item as={Link} to="/adminlogin">
//                       <FaUserShield style={{ marginRight: '8px' }} />
//                       Admin Login
//                     </Dropdown.Item>
//                   </>
//                 )}
//               </Dropdown.Menu>
//             </Dropdown>
//             <Nav.Link as={Link} to="/wishlist" className="position-relative ms-3">
//               <BsHeart size={25} />
//               {totalWishlistItems > 0 && (
//                 <span className="badge rounded-pill bg-dark position-absolute top-0 start-100 translate-middle">
//                   {totalWishlistItems}
//                 </span>
//               )}
//             </Nav.Link>
//             <Nav.Link as={Link} to="/cart" className="position-relative ms-3">
//               <BsCart3 size={25} />
//               {cartLength > 0 && (
//                 <span className="badge rounded-pill bg-dark position-absolute top-0 start-100 translate-middle">
//                   {cartLength}
//                 </span>
//               )}
//             </Nav.Link>
//           </Nav>
//         </Container>

//         {/* Visible on sm screens */}
//         <Container className="d-md-none">
//           <Navbar.Toggle aria-controls="basic-navbar-nav" />
//           <Navbar.Collapse id="basic-navbar-nav">
//             <Nav className="me-auto">
//               <Nav.Link as={Link} to="/"><strong className='text-black'>Home</strong></Nav.Link>
//               <Nav.Link as={Link} to="/about"><strong className='text-black'>About</strong></Nav.Link>
//               <Nav.Link as={Link} to="/trend"><strong className='text-black'>Trending</strong></Nav.Link>
//               <Nav.Link as={Link} to="/brace"><strong className='text-black'>Bracelets</strong></Nav.Link>
//               <Nav.Link as={Link} to="/earrings"><strong className='text-black'>Earrings</strong></Nav.Link>
//               <Nav.Link as={Link} to="/necklaces"><strong className='text-black'>Necklaces</strong></Nav.Link>
//               <Nav.Link as={Link} to="/rings"><strong className='text-black'>Rings</strong></Nav.Link>
//             </Nav>
//           </Navbar.Collapse>
//           <img src={Logor} className='d-block mx-auto' style={{ height: '40px', width: '150px', borderRadius: '20px' }} alt="Logo" />
//           <Nav className="d-flex flex-row align-items-center my-lg-0" style={{ fontSize: '16px' }}>
//             <div className="d-flex align-items-center">
//               <IoSearchOutline size={25} onClick={handleSearchModalOpen} style={{ cursor: 'pointer' }} />
//               <Modal show={showSearchModal} onHide={handleSearchModalClose}>
//                 <Modal.Body>
//                   <Form className="d-flex">
//                     <Form.Control
//                       type="search"
//                       placeholder="Search"
//                       aria-label="Search"
//                       value={searchQuery}
//                       onChange={handleSearchChange}
//                       onKeyPress={handleSearchSubmit}
//                     />
//                   </Form>
//                 </Modal.Body>
//               </Modal>
//             </div>
//             <Dropdown align="end" className="me-3 d-block d-md-none">
//               <div id="account-dropdown" className="text-black">
//                 {isLoggedIn ? (
//                   <Dropdown.Item onClick={handleLogin}><RiAccountCircleLine size={25} /></Dropdown.Item>
//                 ) : (
//                   <Link to="/login">
//                     <RiAccountCircleLine size={25} />
//                   </Link>
//                 )}
//               </div>
//             </Dropdown>
//             <div className="d-flex align-items-center">
//               <Nav.Link as={Link} to="/wishlist" className="d-flex align-items-center me-3 position-relative">
//                 <BsHeart size={25} />
//                 {totalWishlistItems > 0 && (
//                   <span className="badge rounded-pill bg-dark position-absolute top-0 start-100 translate-middle">
//                     {totalWishlistItems}
//                   </span>
//                 )}
//               </Nav.Link>
//               <Nav.Link as={Link} to="/cart" className="d-flex align-items-center position-relative">
//                 <BsCart3 size={25} />
//                 {cartLength > 0 && (
//                   <span className="badge rounded-pill bg-dark position-absolute top-0 start-100 translate-middle">
//                     {cartLength}
//                   </span>
//                 )}
//               </Nav.Link>
//             </div>
//           </Nav>
//         </Container>
//       </Navbar>
//       {/* <hr style={{ color: 'lightslategray' }} /> */}
//     </>
//   );
// }

// export default LandingHeader;



import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import Logor from '../images/Logop.png';
import { RiAccountCircleLine } from 'react-icons/ri';
import Dropdown from 'react-bootstrap/Dropdown';
import { BsCart3, BsHeart } from 'react-icons/bs';
import { IoSearchOutline } from "react-icons/io5";
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { FaSignInAlt } from 'react-icons/fa';
import { FaUserShield } from 'react-icons/fa6';
import { AiOutlineUser } from 'react-icons/ai';
import './LandingPage.css';
import { GiHamburgerMenu } from 'react-icons/gi';
function LandingHeader({ setSearch }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const wishlistItems = useSelector(state => state.wishlist.wishlistItems);
  const totalWishlistItems = wishlistItems.length;
  const totalQuantity = useSelector(state => state.cart.totalQuantity);


  useEffect(() => {
    const token = localStorage.getItem('userData');
    setIsLoggedIn(!!token);
  }, []);


  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    console.log('searchQuery updated:', event.target.value);
  };

  const handleSearchSubmit = (event) => {
    console.log('searchQuery:', searchQuery);
    if (event.key === 'Enter') {
      navigate(`/searchresults?query=${encodeURIComponent(searchQuery)}`);
      setShowSearchModal(false);
    }
  };

  const handleLogin = () => {
    navigate('/myaccount');
  };


  const handleSearchModalOpen = () => setShowSearchModal(true);
  const handleSearchModalClose = () => setShowSearchModal(false);

  const cartLength = localStorage.getItem('cartlength')




  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Navbar expand="lg" style={{ fontFamily: 'Arial, sans-serif' }}>
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              src={Logor}
              className='d-none d-lg-block me-auto' // Change here
              style={{ height: '90px', width: '250px', borderRadius: '20px' }}
              alt="Logo"
            />
          </Navbar.Brand>

          <Nav className="me-auto d-none d-lg-flex">
            <Nav.Link as={Link} to="/" className="nav-link"><strong style={{ color: '#ff5722' }}>H o m e</strong></Nav.Link>
            <Nav.Link as={Link} to="/about" className="nav-link"><strong className='text-black'>A b o u t</strong></Nav.Link>
            <Nav.Link as={Link} to="/trend" className="nav-link"><strong className='text-black'>T r e n d i n g</strong></Nav.Link>
            <Nav.Link as={Link} to="/brace" className="nav-link"><strong className='text-black'>B r a c e l e t s</strong></Nav.Link>
            <Nav.Link as={Link} to="/earrings" className="nav-link"><strong className='text-black'>E a r r i n g s</strong></Nav.Link>
            <Nav.Link as={Link} to="/necklaces" className="nav-link"><strong className='text-black'>N e c k l a c e s</strong></Nav.Link>
            <Nav.Link as={Link} to="/rings" className="nav-link"><strong className='text-black'>R i n g s</strong></Nav.Link>
          </Nav>

          <Nav className="my-lg-0 d-none d-lg-flex align-items-center" style={{ fontSize: '16px' }}>
            <div className="d-flex align-items-center position-relative">
              <IoSearchOutline size={30} onClick={handleSearchModalOpen} style={{ cursor: 'pointer' }} />
              <Modal show={showSearchModal} onHide={handleSearchModalClose}>
                <Modal.Body>
                  <input
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    onKeyPress={handleSearchSubmit}
                  />
                </Modal.Body>
              </Modal>
            </div>
            <Dropdown align="end" className="ms-3">
              <Dropdown.Toggle variant="link" id="account-dropdown" className="text-black d-flex align-items-center">
                <RiAccountCircleLine size={25} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {isLoggedIn ? (
                  <Dropdown.Item onClick={handleLogin} style={{ display: 'flex', alignItems: 'center' }}>
                    <AiOutlineUser style={{ marginRight: '5px' }} />
                    MY ACCOUNT
                  </Dropdown.Item>
                ) : (
                  <>
                    <Dropdown.Item as={Link} to="/login">
                      <FaSignInAlt style={{ marginRight: '8px' }} />
                      Login In
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/adminlogin">
                      <FaUserShield style={{ marginRight: '8px' }} />
                      Admin Login
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <Nav.Link as={Link} to="/wishlist" className="position-relative ms-3">
              <BsHeart size={25} />
              {totalWishlistItems > 0 && (
                <span className="badge rounded-pill bg-dark position-absolute top-0 start-100 translate-middle">
                  {totalWishlistItems}
                </span>
              )}
            </Nav.Link>
            <Nav.Link as={Link} to="/cart" className="position-relative ms-3">
              <BsCart3 size={25} />
              {cartLength > 0 && (
                <span className="badge rounded-pill bg-dark position-absolute top-0 start-100 translate-middle">
                  {cartLength}
                </span>
              )}
            </Nav.Link>
          </Nav>
        </Container>

        {/* Responsive Drawer for small screens */}
        <Container className="d-lg-none">
          <button className="menu-toggle-btn" onClick={toggleDrawer}>
            <GiHamburgerMenu size={25} />
          </button>
          <div className={`menu-drawer ${isOpen ? 'open' : ''}`}>
            <div className="menu-drawer__inner-container">
              <Nav className="menu-drawer__navigation-container">
                <Nav.Link as={Link} to="/" style={{ color: '#ff5722' }}>Home</Nav.Link>
                <Nav.Link as={Link} to="/about">About</Nav.Link>
                <Nav.Link as={Link} to="/trend">Trending</Nav.Link>
                <Nav.Link as={Link} to="/brace">Bracelets</Nav.Link>
                <Nav.Link as={Link} to="/earrings">Earrings</Nav.Link>
                <Nav.Link as={Link} to="/necklaces">Necklaces</Nav.Link>
                <Nav.Link as={Link} to="/rings">Rings</Nav.Link>
              </Nav>
            </div>
          </div>

          <Navbar className="d-lg-none ">
            <img src={Logor} className='d-block mx-auto' style={{ height: '40px', width: '150px', borderRadius: '20px' }} alt="Logo" />
            <Nav className="d-flex flex-row align-items-center my-lg-0" style={{ fontSize: '16px' }}>
              <div className="d-flex align-items-center">
                <IoSearchOutline size={25} onClick={handleSearchModalOpen} style={{ cursor: 'pointer' }} />
                <Modal show={showSearchModal} onHide={handleSearchModalClose}>
                  <Modal.Body>
                    <input
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      onKeyPress={handleSearchSubmit}
                    />
                  </Modal.Body>
                </Modal>
              </div>
              <Dropdown align="end" className="me-2">
                <div id="account-dropdown" className="text-black">
                  {isLoggedIn ? (
                    <Dropdown.Item onClick={handleLogin}><RiAccountCircleLine size={25} style={{ color: 'black' }} /></Dropdown.Item>
                  ) : (
                    <Link to="/login">
                      <RiAccountCircleLine size={25} style={{ color: 'black' }} />
                    </Link>
                  )}
                </div>
              </Dropdown>
              <div className="d-flex align-items-center">
                <Nav.Link as={Link} to="/wishlist" className="d-flex align-items-center me-2 position-relative">
                  <BsHeart size={24} />
                  {totalWishlistItems > 0 && (
                    <span className="badge rounded-pill bg-dark position-absolute top-0 start-100 translate-middle">
                      {totalWishlistItems}
                    </span>
                  )}
                </Nav.Link>
                <Nav.Link as={Link} to="/cart" className="d-flex align-items-center position-relative">
                  <BsCart3 size={25} />
                  {cartLength > 0 && (
                    <span className="badge rounded-pill bg-dark position-absolute top-0 start-100 translate-middle">
                      {cartLength}
                    </span>
                  )}
                </Nav.Link>
              </div>
            </Nav>
          </Navbar>
        </Container>
      </Navbar>

    </>
  );
}

export default LandingHeader;
